<template>
  <div class="d-flex flex-row flex-wrap" id="signIn" style="min-height: 100vh;">
    <div
      class="d-flex flex-column flex-wrap login__left"
      style="flex-grow: 2;"
      :style="{ background: `url(${bg})`, backgroundSize: 'cover' }"
    >
      <div
        class="d-flex flex-row justify-center  flex-grow-1 backdrop mt-9"
      ></div>
    </div>
    <div
      class="d-flex flex-column flex-wrap flex-shrink-0 flex-grow-1 align-content-lg-center justify-center"
    >
      <div class="d-flex flex-row mt-n6 ">
        <h3
          class="pl-4 pt-1 primary--text font-weight-bold font-italic ft text-xl logo-shadow"
        ></h3>
      </div>
      <v-card
        flat
        :loading="isLoading"
        id="loginCard"
        class="rounded-sm ma-3 pl-4 pr-4 pb-6 mt-n12 d-flex flex-column align-content-center"
        style="min-width: 400px !important;"
        v-if="state === false"
      >
        <div
          class="signin mx-2 mb-5 mt-4 d-flex flex-column flex-grow-1 align-center"
        >
          <img
            src="@/assets/android-icon-192x192.png"
            style="width: 100px;height: 100px;"
          />
          <div class="ft text-md d-flex flex-row justify-center pt-3 pl-1">
            <h3>Sign in to your account</h3>
          </div>
        </div>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(login)">
            <ValidationProvider
              name="email"
              tag="div"
              class="d-flex flex-column flex-fill flex-wrap"
              rules="required"
              v-slot="{ errors }"
            >
              <div class="d-flex flex-column flex-grow-1 flex-shrink-1">
                <v-text-field
                  outlined
                  class="font font-weight-medium mt-2"
                  type="text"
                  label="Email"
                  v-model="email"
                  :error-messages="errors[0]"
                  :autofocus="true"
                />
              </div>
            </ValidationProvider>
            <ValidationProvider
              name="password"
              rules="required"
              v-slot="{ errors }"
            >
              <div class="d-flex flex-row flex-grow-1 flex-shrink-1">
                <v-text-field
                  outlined
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  class="font font-weight-medium mt-2"
                  :type="show ? 'text' : 'password'"
                  ref="password"
                  @click:append="show = !show"
                  label="Password"
                  v-model="password"
                  :error-messages="errors[0]"
                />
              </div>
            </ValidationProvider>
            <div class="d-flex flex-row flex-grow-1 flex-wrap">
              <v-btn
                tag="a"
                small
                @click="$router.push({ name: 'blank.forgot-password' })"
                color="primary"
                class="mt-n1 text-sm  font text-capitalize"
                text
                >Forgot Password?
              </v-btn>
            </div>
            <v-card-actions class="justify-end mt-1 pl-0 pr-0 pb-2">
              <v-btn
                type="submit"
                :disabled="isLoading"
                color="primary"
                block
                :loading="isLoading"
                @click="login"
                class="font text-sm text-capitalize"
                >Login
              </v-btn>
            </v-card-actions>
          </form>
        </ValidationObserver>
      </v-card>
    </div>

    <SnackBar
      :showSnackBar="showSnackBar"
      :message="message"
      :status="status"
    />
  </div>
</template>

<script>
import * as axios from "axios";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
// Add the required rule
extend("required", {
  ...required,
  message: "This field is required"
});

// Add the email rule
extend("email", {
  ...email,
  message: "This field must be a valid email"
});

export default {
  name: "Login",
  components: {
    ValidationProvider,
    ValidationObserver,
    SnackBar: () => import(`../../components/SnackBar`)
  },
  data() {
    return {
      email: "",
      state: false,
      showSnackBar: false,
      bg: require("@/assets/station.jpg"),
      password: "",
      isLoading: false,
      load: false,
      timeout: 4000,
      message: "",
      status: "",
      closeSnackBar: false,
      error: "",
      forgotClicked: false,
      loader: false,
      show: false,
      snackbar: false
    };
  },
  methods: {
    login() {
      if (this.email !== "" && this.password !== "") {
        this.isLoading = true;
        setTimeout(() => {
          axios({
            method: "post",
            url: `${process.env.VUE_APP_BASE}/auth/login`,
            data: {
              username: this.email.trim(),
              password: this.password.trim()
            }
          })
            .then(response => {
              this.isLoading = true;
              if (response.data.userType !== "site-manager") {
                this.isLoading = false;
                this.showSnackBar = true;
                this.message =
                  "Sorry, you're trying to access this portal with different credentials. Check and try again.";
                this.status = "red";
                setTimeout(() => (this.showSnackBar = false), 2000);
              } else {
                localStorage.setItem("token", response.data.access_token);
                this.$router.push({ name: "master.dashboard" }).catch(() => {});
              }
            })
            .catch(err => {
              this.isLoading = false;
              this.showSnackBar = true;
              this.status = "red";
              this.message =
                err?.response?.data?.message ??
                "Access to user's account failed. Try again";
              setTimeout(() => (this.showSnackBar = false), 2000);
            });
        }, 1000);
      } else {
        this.showSnackBar = true;
        this.message = "All field must be filled.";
      }
    }
  }
};
</script>
<style scoped></style>
